// boostrap
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "color-variables.scss";
//carousel
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "style.scss";
@import "feathericon.min.css";
@import "../plugins/fontawesome/css/fontawesome.css";
@import url(https://unpkg.com/@jcubic/tagger@0.x.x/tagger.css);

@import '~react-toastify/dist/ReactToastify.css';
@import "node_modules/@pathofdev/react-tag-input/src/styles/index.scss";
@import '~react-confirm-alert/src/react-confirm-alert.css';


textarea.form-control {
  height: auto;
}

div.head {
  color: #333;
  font-weight: 600;
  border-bottom: 2px solid #dee2e6;
  border-color: #333;
}

div.rowbody {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.mr-10 {
  margin-right: 10px;
}

.mr-5px {
  margin-right: 5px;
}

div.head {
  color: #333;
  font-weight: 600;
  border-bottom: 2px solid #dee2e6;
  border-color: #333;
}

div.rowbody {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

div.divcontainer {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

div .react-tag-input {
  min-height: 20px;
}

.tagger {
  width: 300px;
}

.grid-wrapper {
  margin-bottom: 20px;
  border: solid 2px #eceff2;

  .grid-header {
    padding: 0.5rem 0.5rem;
    font-weight: bold;
    background: #f8f9fa;
    line-height: 24px;
    border-bottom: 2px solid currentColor;
  }

  .grid-block {
    border-bottom: solid 2px #eceff2;
    padding: 0.5rem;

    &:nth-child(even) {
      background: #f2f2f2;
    }

    &.disabled {
      opacity: 0.25;
      cursor: not-allowed;
    }
  }


}

.popup-box {
  position: fixed;
  background: #00000050;
  width: 80%;
  height: 100vh;
  top: 0;
  left: 5;
}

.box {
  position: relative;
  width: 80%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 10px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}


.close-icon {
  text-align-last: right;
}

.custom-ui {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: #ffffff;
  border: solid 1px rgb(0 0 0 / 23%);
  box-shadow: 0 20px 75px rgb(0 0 0 / 23%);

  h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .btn {
    min-width: 150px;
    padding: 10px;
    margin-left: 10px;
  }
}

.select {
  font-size: medium;
}

.field-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.react-tag-input__input {
  min-height: 50px;
}

.space-wrap {
  white-space: nowrap;
  justify-content: center;
}

.space-wrap input {
  margin-right: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mt-5p {
  margin-top: 5px;
}

.preview-answers label {
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  text-transform: capitalize;
}

.background-transparent {
  background: transparent;
}

.background-transparent svg path {
  fill: #000;
}

.mtb10-mlrauto {
  margin: 10px auto;
}

.preview-form {
  .grid-wrapper {
    border: 1px solid #1b5a90;
  }

  .grid-header {
    background: #99b7d0;
    color: #fff;
  }
}

.user-menu
{
  display:flex;
justify-content: center;
vertical-align: middle;
align-items: center;
}
.patients-list-wrapper, .doctor-list-wrapper {
  &.isadmin {
    .status, .actions {
      display: none;
    }
  }
}
@media print {
  .sidebar,
  #toggle_btn,
  .notify,
  .page-header span,
  .buttons-wrap .btn {
    display: none;
  }
  .page-wrapper {
    margin-left: 0px;
    padding-top: 60px;
  }
}
.form-group-array {
  .invalid-feedback-array {
    width: 0%;
    margin-top: 0;
    font-size: 0;
    color: #dc3545;
    & ~ .form-control {
      border-color: #dc3545;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }
  .invalid-feedback {
    display: block;
  }
}

.form-daterange-group{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  label{
    min-width: 100px;
    margin-right: 10px;
    text-align: right;
  }
  label + input.form-control{
    width: calc(100% - 110px);
  }
  .daterange_icon{
    position: absolute;
    right: calc(0px + 10px);
    opacity: 0.5;
  }
}
